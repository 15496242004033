.footer {
  padding: 2vh 0 0;
  text-align: center;
  height: 100%;
  bottom: 0;
}

.logo-vo{
  margin: 0;
  padding: 0;
  height: 20vh;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  color: black;
  font-weight: 500;
}

.footer nav ul {
  list-style: none;
  padding: 0;
}

.footer nav ul li {
  display: inline;
  margin: 0 10px;
}

.footer nav ul li a {
  color: black;
  text-decoration: none;
}

.footer nav ul li a:hover {
  text-decoration: underline;
}
  