.trailsText {
    position: relative;
    width: 100%;
    height: 12vh;
    line-height: 70px;
    color: #111;
    font-size: 6vh;
    font-weight: 700;
    text-shadow: 4px 4px 8px #0808085d;
    letter-spacing: -0.000005em;
    margin-top: 5vh;
    will-change: transform, opacity;
    overflow: hidden;
    z-index: 5;
  }
  
  .trailsText > div {
    padding-right: 0.05em;
    overflow: hidden;
  }
  
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  
  @media screen and (max-width: 1200px) {
    .trailsText{
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .trailsText{
      width: 150%;
    }
  }