/* ContactForm.css */
#contact {
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 2vh;
  background-color: #07273c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.qr-escan{
  padding: 0;
  margin: 0;
  width: auto;
  height: 70vh;
  margin-top: -8vh;
}

.contact-header {
  text-align: center;
  width: 100%;
  padding: 0 3vh;
}

.contact-header h2 {
  font-size: 5vh;
  margin: 0 auto 3vh;
  color: white; 
}

.contact-header p {
  font-size: 2.2vh;
  color: #6c6c6c; /* Gris oscuro */
}

.contact-form {
  width: 100%;
  margin-top: 2vh;
  padding-right: 10vh;
}

.form-group {
  margin-bottom: 3vh;
}

.form-group label {
  display: block;
  margin-bottom: 2vh;
  font-weight: bold;
  color: white;
}

.form-group input, .form-group select, .form-group textarea {
  width: 100%;
  padding: 1vh;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  background-color: #002244; 
  color: white;
  cursor: pointer;
  font-size: 2vh;
  transform: translateY(-0.4vh);
  padding: 1vh 5vh;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
  box-shadow: 0 5px 15px #00336641;
}

.submit-button:hover {
  background-color: white;
  color: #002244;
}

.success-message, .error-message {
  text-align: center;
  font-size: 2vh;
  margin-top: 15px;
}

.success-message {
  color: white;
}

.error-message {
  color: white;
}
  
@media screen and (max-width: 1000px) {
  #contact {
    width: 100%;
    padding-top: 3vh;
    padding-bottom: 2vh;
    background-color: #003366;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-form {
    width: 100%;
    padding: 2vh 4vh;
  }

  .qr-escan{
    display: none;
  }

}