.publicidad-section {
  padding: 5vh 2vh 6vh;
  background-color:#33C1FF;
  text-align: center;
}

.publicidad-contenedor {
  max-width: 85%;
  margin: 0 auto;
}

.publicidad-titulo {
  font-size: 6vh;
  margin-bottom: 25px;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
}


.publicidad-texto {
  font-size: 2.5vh;
  color: white;
  margin-bottom: 30px;
  line-height: 1.6;
  display: inline-block;
  padding-bottom: 10px;
  font-weight: 500;
}

.publicidad-boton {
  background:#07273c;
  color: #fff;
  padding: 1.5vh 3vh;
  border-radius: 50px;
  text-decoration: none;
  font-size: 2vh;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.publicidad-boton:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
  transform: translateY(-1vh); /* Eleva el botón al pasar el ratón */
  color: #fff;
}


.publicidad-boton:active {
  transform: scale(0.98);
}

/* QUERIES */
@media screen and (max-width: 1300px){
  
.publicidad-titulo {
  font-size: 4vh;
  margin-bottom: 25px;
  color: #07273c;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
}
}
