.container-father-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding: 10vh 15vh;
    background-color: white;
    background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
    background-size: 16px 16px;
}

.styles_trailsText__\+Y3w0 {
    
    text-shadow: 0.5px 0.5px 1.8px #0033662a;
}

/* Estilos para el lado izquierdo */
.left-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.left-side .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

/* Estilos para el lado derecho */
.right-side {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.right-side .container-right {
    padding-left: 6vh;
    height: 100%;
    top: 0;
    text-align: center;
    align-items: start;
}

.right-side h1 {
    color:  #111;
    font-weight: 800;
    font-size: 7vh;
    text-align: start;
    margin-bottom: 3vh;
    text-shadow: 0.5px 0.5px 1.8px #0033662a;
}

.right-side h2 {
    color: none; 
    text-align: start;
    text-shadow: 0.5px 0.5px 1.8px #0033662a;
    margin: 5vh auto;
}

.container-btn-head{
    width: 100%;
    display: flex;
    justify-content: start;
}

.right-side button {
    font-size: 2vh;
    background: #07273c;
    font-weight: 600;
    color: #fff;
    transform: translateY(-0.4vh);
    padding: 1vh 5vh;
    border: none;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.4s ease, box-shadow 0.4s ease, transform 0.4s ease;
    box-shadow: 0 5px 15px #00336641;
}

.right-side button:hover {
    background: #003366; /* Color de fondo más oscuro al pasar el ratón */
    box-shadow: 0 10px 20px #0033662a; /* Sombra más pronunciada */
    transform: translateY(-1vh); /* Eleva el botón al pasar el ratón */
}

.right-side button:hover {
    background-color: #1A1F36;
}

@media screen and (max-width: 1200px) {
    .container-father-header {
        width: 100%;
        justify-content: center;
    }

    .right-side {
        width: 100%;
    }
    
    .right-side .container-right {
        padding: 0;
        text-align: center;
    }


    .left-side .container {
        margin-top: 0vh;
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 800px) {

    .container-father-header {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        padding: 10vh 5vh;
    }

    .left-side  {
        display: none;
    }

    .right-side h1 {
        font-size: 5vh;
        text-align: center;
    }
    
    .right-side h2 {
        text-align: center;
    }
    
    .container-btn-head{
        justify-content: center;
    }
    
}
