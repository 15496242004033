.scroll-animation-wrapper {
  height: max-content;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.scroll-animation-wrapper.in-view {
  opacity: 1;
  transform: translateY(0);
}